@font-face {
  font-family: 'Accredible-icon-font';
  src: url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.eot?el5zxc');
  src: url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.eot?el5zxc#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.woff2?el5zxc') format('woff2'),
    url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.ttf?el5zxc') format('truetype'),
    url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.woff?el5zxc') format('woff'),
    url('/assets/fonts/acc-new-icon-font/Accredible-icon-font.svg?el5zxc#Accredible-icon-font')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Accredible-icon-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rte-aligned-center:before {
  content: '\e9aa';
}

.icon-rte-aligned-left:before {
  content: '\e9ab';
}

.icon-rte-aligned-right:before {
  content: '\e9ac';
}

.icon-rte-attributes:before {
  content: '\e9ad';
}

.icon-rte-image:before {
  content: '\e9ae';
}

.icon-rte-line:before {
  content: '\e9af';
}

.icon-rte-quote:before {
  content: '\e9b0';
}

.icon-rte-table:before {
  content: '\e9b1';
}

.icon-rte-video:before {
  content: '\e9b2';
}

.icon-add-square:before {
  content: '\e9a9';
}

.icon-cash:before {
  content: '\e9a6';
}

.icon-attach:before {
  content: '\e9a3';
}

.icon-message_bubble:before {
  content: '\e9a4';
}

.icon-rte-bold:before {
  content: '\e99d';
}

.icon-rte-bullet:before {
  content: '\e99e';
}

.icon-rte-italic:before {
  content: '\e99f';
}

.icon-rte-link:before {
  content: '\e9a0';
}

.icon-rte-number:before {
  content: '\e9a1';
}

.icon-rte-underline:before {
  content: '\e9a2';
}

.icon-rte-text:before {
  content: '\e99c';
}

.icon-send1:before {
  content: '\e996';
}

.icon-turn-off:before {
  content: '\e997';
}

.icon-guide:before {
  content: '\e948';
}

.icon-aligned_bottom:before {
  content: '\e944';
}

.icon-aligned_middle:before {
  content: '\e945';
}

.icon-aligned_top:before {
  content: '\e946';
}

.icon-norelation:before {
  content: '\e943';
}

.icon-arrow-down:before {
  content: '\e903';
}

.icon-arrow-left:before {
  content: '\e904';
}

.icon-arrow-right:before {
  content: '\e905';
}

.icon-arrow-up:before {
  content: '\e906';
}

.icon-button-dropdown:before {
  content: '\e907';
}

.icon-button-dropleft:before {
  content: '\e908';
}

.icon-button-dropright:before {
  content: '\e909';
}

.icon-button-dropup:before {
  content: '\e90a';
}

.icon-chevron-down:before {
  content: '\e90b';
}

.icon-chevron-right:before {
  content: '\e90c';
}

.icon-chevron-left:before {
  content: '\e90d';
}

.icon-chevron-up:before {
  content: '\e90e';
}

.icon-field-dropdown:before {
  content: '\e90f';
}

.icon-field-dropleft:before {
  content: '\e910';
}

.icon-field-dropright:before {
  content: '\e911';
}

.icon-field-dropup:before {
  content: '\e912';
}

.icon-close-small:before {
  content: '\e915';
}

.icon-close:before {
  content: '\e919';
}

.icon-check:before {
  content: '\e91a';
}

.icon-copy:before {
  content: '\e91b';
}

.icon-globe:before {
  content: '\e91e';
}

.icon-user:before {
  content: '\e92b';
}

.icon-private:before {
  content: '\e924';
}

.icon-link:before {
  content: '\e91d';
}

.icon-delete:before {
  content: '\e917';
}

.icon-calendar:before {
  content: '\e913';
}

.icon-download:before {
  content: '\e918';
}

.icon-upload:before {
  content: '\e92a';
}

.icon-info:before {
  content: '\e91c';
}

.icon-menu-kebab:before {
  content: '\e920';
}

.icon-clock:before {
  content: '\e914';
}

.icon-search:before {
  content: '\e926';
}

.icon-mail:before {
  content: '\e91f';
}

.icon-credential:before {
  content: '\e916';
}

.icon-view:before {
  content: '\e92c';
}

.icon-shares:before {
  content: '\e927';
}

.icon-external:before {
  content: '\e921';
}

.icon-suitcase:before {
  content: '\e929';
}

.icon-filters:before {
  content: '\e922';
}

.icon-help1:before {
  content: '\e956';
}

.icon-share1:before {
  content: '\e958';
}

.icon-embed-code:before {
  content: '\e955';
}

.icon-edit1:before {
  content: '\e954';
}

.icon-wallet:before {
  content: '\e959';
}

.icon-image:before {
  content: '\e957';
}

.icon-transcript:before {
  content: '\e95a';
}

.icon-replace:before {
  content: '\e96a';
}

.icon-loading:before {
  content: '\e928';
}

.icon-redo:before {
  content: '\e96c';
}

.icon-undo:before {
  content: '\e96d';
}

.icon-insights-chart:before {
  content: '\e96b';
}

.icon-opened:before {
  content: '\e92d';
}

.icon-pdf:before {
  content: '\e92e';
}

.icon-sub-arrow:before {
  content: '\e92f';
}

.icon-visible-user:before {
  content: '\e930';
}

.icon-remove:before {
  content: '\e925';
}

.icon-plus:before {
  content: '\e923';
}

.icon-play:before {
  content: '\e94e';
}

.icon-click:before {
  content: '\e94c';
}

.icon-heart:before {
  content: '\e94d';
}

.icon-customer-support:before {
  content: '\e95b';
}

.icon-announcement:before {
  content: '\e951';
}

.icon-hide:before {
  content: '\e953';
}

.icon-groups:before {
  content: '\e95d';
}

.icon-featured-group:before {
  content: '\e95c';
}

.icon-menu-horizontal:before {
  content: '\e95e';
}

.icon-settings:before {
  content: '\e95f';
}

.icon-accredible:before {
  content: '\e96e';
}

.icon-drag:before {
  content: '\e976';
}

.icon-telegram:before {
  content: '\e9b3';
}

.icon-threads:before {
  content: '\e9b4';
}

.icon-whatsapp1:before {
  content: '\e9a8';
}

.icon-twitter1:before {
  content: '\e995';
}

.icon-tiktok1:before {
  content: '\e902';
}

.icon-x1:before {
  content: '\e97a';
}

.icon-wechat1:before {
  content: '\e947';
}

.icon-instagram1:before {
  content: '\e97f';
}

.icon-youtube1:before {
  content: '\e980';
}

.icon-facebook1:before {
  content: '\e900';
}

.icon-linkedin1:before {
  content: '\e901';
}

.icon-ec-certificate:before {
  content: '\e984';
}

.icon-ec-course:before {
  content: '\e985';
}

.icon-ec-degree:before {
  content: '\e986';
}

.icon-ec-exam:before {
  content: '\e987';
}

.icon-ec-experience:before {
  content: '\e988';
}

.icon-ec-knowledge:before {
  content: '\e989';
}

.icon-ec-license:before {
  content: '\e98a';
}

.icon-ec-other:before {
  content: '\e98b';
}

.icon-ec-participation:before {
  content: '\e98c';
}

.icon-ec-reading:before {
  content: '\e98d';
}

.icon-ec-skill:before {
  content: '\e98e';
}

.icon-ec-specialization:before {
  content: '\e98f';
}

.icon-ec-visa:before {
  content: '\e990';
}

.icon-mail-open:before {
  content: '\e9b7';
}

.icon-transcripts:before {
  content: '\e9b8';
}

.icon-ai:before {
  content: '\e9a7';
}

.icon-fire:before {
  content: '\e9a5';
}

.icon-lock:before {
  content: '\e998';
}

.icon-schedule:before {
  content: '\e999';
}

.icon-send:before {
  content: '\e99a';
}

.icon-unsend:before {
  content: '\e99b';
}

.icon-phone:before {
  content: '\e991';
}

.icon-fill-filter_empty:before {
  content: '\e97c';
}

.icon-paste:before {
  content: '\e97d';
}

.icon-sticky-note:before {
  content: '\e97e';
}

.icon-grid:before {
  content: '\e933';
}

.icon-timeline:before {
  content: '\e93d';
}

.icon-list:before {
  content: '\e937';
}

.icon-share:before {
  content: '\e93a';
}

.icon-info1:before {
  content: '\e936';
}

.icon-warning:before {
  content: '\e93f';
}

.icon-error:before {
  content: '\e932';
}

.icon-check1:before {
  content: '\e931';
}

.icon-verified:before {
  content: '\e93e';
}

.icon-blockchain:before {
  content: '\e934';
}

.icon-location:before {
  content: '\e938';
}

.icon-group:before {
  content: '\e935';
}

.icon-clock1:before {
  content: '\e939';
}

.icon-sort:before {
  content: '\e93c';
}

.icon-help:before {
  content: '\e93b';
}

.icon-pdf1:before {
  content: '\e940';
}

.icon-signature:before {
  content: '\e941';
}

.icon-change:before {
  content: '\e942';
}

.icon-gift:before {
  content: '\e949';
}

.icon-gear:before {
  content: '\e94a';
}

.icon-star:before {
  content: '\e94b';
}

.icon-heart1:before {
  content: '\e94f';
}

.icon-user1:before {
  content: '\e950';
}

.icon-edit:before {
  content: '\e952';
}

.icon-thumbs-up:before {
  content: '\e968';
}

.icon-thumbs-down:before {
  content: '\e967';
}

.icon-certificate-with-badge:before {
  content: '\e961';
}

.icon-certificate:before {
  content: '\e962';
}

.icon-click1:before {
  content: '\e963';
}

.icon-view1:before {
  content: '\e969';
}

.icon-hide1:before {
  content: '\e964';
}

.icon-badge:before {
  content: '\e960';
}

.icon-sort1:before {
  content: '\e966';
}

.icon-mail1:before {
  content: '\e965';
}

.icon-insights2:before {
  content: '\e96f';
}

.icon-percentage:before {
  content: '\e970';
}

.icon-text:before {
  content: '\e971';
}

.icon-skip:before {
  content: '\e972';
}

.icon-check-list-page:before {
  content: '\e974';
}

.icon-collection-folder:before {
  content: '\e975';
}

.icon-telegram1:before {
  content: '\e9b5';
}

.icon-threads1:before {
  content: '\e9b6';
}

.icon-x:before {
  content: '\e994';
}

.icon-twitter:before {
  content: '\e993';
}

.icon-tiktok:before {
  content: '\e992';
}

.icon-wechat:before {
  content: '\e983';
}

.icon-instagram:before {
  content: '\e981';
}

.icon-youtube:before {
  content: '\e982';
}

.icon-email:before {
  content: '\e973';
}

.icon-facebook:before {
  content: '\e977';
}

.icon-linkedin:before {
  content: '\e978';
}

.icon-slack:before {
  content: '\e979';
}

.icon-whatsapp:before {
  content: '\e97b';
}

.icon-minus:before {
  content: '\e9bc';
}
